import { motion } from "framer-motion";

function DownBtn() {
  const toApplyStyle = {
    border: "none",
    borderRadius: "50px",
    height: "100px",
    width: "100px",
    backgroundPosition: "center",
    // backgroundRepeat: 'no-repeat',
    backgroundSize: "cover",
    backgroundImage: `url(${"/img/DownBtn.jpg"})`,
    cursor: "pointer",
  };

  return (
    <motion.div
      initial={{ y: -25 }}
      animate={{ y: 25 }}
      transition={{
        repeat: Infinity,

        duration: 3,
        delay: 3,
      }}
      style={{ marginLeft: "15px", marginRight: "15px" }}
    >
      <button
        style={toApplyStyle}
        onClick={() => {
          window.open("https://www.youtube.com/@aayushgore101", "_blank");
        }}
      ></button>
    </motion.div>
  );
}

export default DownBtn;
