import TypeIt from "typeit-react";

import Icon from "./Icon";

function SmallerCard() {
  const nameAayush = "Aayush Gore";

  const Icons = [
    {
      img: "/img/youtube.png",
      placeholder: "https://www.youtube.com/@aayushgore101",
      makeCircle: false,
    },
    {
      img: "/img/linkedin-img.png",
      placeholder: "https://au.linkedin.com/in/aayush-gore-b95203218",
      makeCircle: false,
    },
    {
      img: "/img/github-img.png",
      placeholder: "https://github.com/aayushgore3499",
      makeCircle: true,
    },
  ];

  return (
    <div id="small-card-container" style={{ height: "300px", width: "300px" }}>
      <div
        id="name-container"
        style={{
          position: "absolute",
          width: "300px",
          height: "300px",
        }}
      >
        <p
          id="nameAayush"
          style={{
            marginTop: "60px",
            marginLeft: "60px",
            color: "white",
            fontSize: "28px",
            borderBottom: "4px solid white",
            padding: "10px",
            maxWidth: "180px",
          }}
        >
          <div className="App">
            <TypeIt
              options={{
                afterComplete: function (instance) {
                  instance.destroy();
                },
              }}
            >
              {nameAayush}
            </TypeIt>
          </div>
        </p>
        <div
          className="container"
          style={{ position: "absolute", width: "300px" }}
        >
          <div className="row" style={{ height: "100px" }}>
            <div className="col d-flex align-items-center justify-content-center">
              {Icons.map((item) => (
                <Icon
                  img={item.img}
                  placeholder={item.placeholder}
                  makeCircle={item.makeCircle}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmallerCard;
