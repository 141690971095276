import { MantineProvider } from "@mantine/core";
import AayushApp from "./Aayush/AayushApp";
import FeetupApp from "./Feetup/FeetupApp";
import TodoApp from "./todo/TodoApp";
// core styles are required for all packages
import "@mantine/core/styles.css";
import LoungeApp from "./Lounge/LoungeApp";

// other css files are required only if
// you are using components from the corresponding package
// import '@mantine/dates/styles.css';
// import '@mantine/dropzone/styles.css';
// import '@mantine/code-highlight/styles.css';
// ...

function MotherBlog() {
  let curScreen = 1;
  return (
    <MantineProvider>
      {curScreen === 1 && <AayushApp />}
      {curScreen === 2 && <TodoApp />}
      {curScreen === 3 && <FeetupApp />}
      {curScreen === 4 && <LoungeApp />}
    </MantineProvider>
  );
  // if (window.location.host.split(".")[0] === "feetup") {
  //   return <FeetupApp />;
  // }
}

export default MotherBlog;
