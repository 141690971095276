import SmallerCard from "./SmallerCard";
import DownBtn from "./DownBtn";

const ContactCard = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
      }}
    >
      <SmallerCard />
      {/* <DownBtn /> */}
    </div>
  );

  // =============================== (can delete below ) =====================================
  // return (
  //   <div
  //     className="d-flex align-items-center justify-content-center"
  //     style={{
  //       position: "fixed",
  //       height: "100%",
  //       width: "100%",
  //     }}
  //   >
  //     <div className="container h-50" style={{ width: "560px" }}>
  //       <div className="row" style={{ height: "75px" }}>
  //         <div className="col"></div>
  //         <div
  //           className="col d-flex align-items-center justify-content-center"
  //           style={{
  //             color: "white",
  //             fontSize: "28px",
  //             borderBottom: "4px solid white",
  //             // border: "1px solid red",
  //           }}
  //         >
  //           Aayush Gore
  //         </div>
  //         <div className="col"></div>

  //         {/* <div className="col" style={{color: 'white', fontSize: '28px', borderBottom: '4px solid white'}}>
  //           Aayush Gore
  //         </div> */}
  //       </div>
  //       <div className="row" style={{ height: "100px" }}>
  //         <div className="col d-flex align-items-center justify-content-center">
  //           {/* <div className="border border-success col d-flex align-items-center justify-content-center"><LinkedinIcon/></div> */}
  //           {/* <div className="border border-success col d-flex align-items-center justify-content-center"><GithubIcon /></div> */}
  //         </div>
  //         {/* <div className="col d-flex align-items-center justify-content-end"><LinkedinIcon/></div> */}
  //         {/* <div className="border border-success col d-flex align-items-center justify-content-end"><LinkedinIcon/></div> */}
  //         {/* <div className="border border-success col d-flex align-items-center justify-content-start"><GithubIcon /></div> */}
  //       </div>
  //     </div>
  //   </div>
  // );
  // =============================== (can delete above ) =====================================
};

export default ContactCard;
