const Chatter = () => {
  window.location.href = 'https://github.com/aayushgore3499/chatter';
  return (
    <div>
      
    </div>
  )
}

export default Chatter
