function AuthorisationAuthentication() {
  return (
    <div
      className="container w-75"
      style={{ marginTop: "30px", backgroundColor: "white", height: "600px" }}
    >
      <div className="row" style={{ height: "20px" }}></div>
      <div className="row p-3" style={{ fontSize: "28px" }}>
        <div className="col-3"></div>
        <div className="col d-flex justify-content-center">
          Authorisation / Authentication Guide
        </div>
        <div className="col-3"></div>
      </div>
      <div className="row" style={{ height: "60px" }}></div>
      <div className="row">
        <div className="col-7">
          <div
            className="row"
            style={{
              fontSize: "18px",
              marginLeft: "100px",
              marginBottom: "20px",
            }}
          >
            Common tools: <br />
            <ul>
              <li>
                <strong>Auth0 Authentication API debugger</strong>
                <p>
                  is a auth0 extension which allows you to mock the interactions
                  your web application will make with an Identity Server.
                  Traditionally, client servers authenticated with servers
                  directly prior to server granting access to protected
                  resource. Nowadays, third party applications such as Google,
                  Microsoft ("ever see login with google or sign in with
                  Microsoft?") require access to certain users' data which they
                  will request access to from the same server. Now if this
                  server grants them access to the resources with the same
                  priviledges as which the user himself contacts the server, we
                  see major conflict and requirement for certain design changes.
                  What we prefer is "LIMITED ACCESS" for Google, Microsoft to
                  access certain elements - Auth0 2.0 saviour! What this does is
                  provide limited access to third party resources on your
                  behalf, that's it! References:
                  https://datatracker.ietf.org/doc/html/rfc6749#section-4.1
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorisationAuthentication;
