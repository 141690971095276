const TcpChattingRoom = () => {
  window.location.href = 'https://github.com/aayushgore3499/tcp-chatting-room';
  return (
    <div>
      
    </div>
  )
}

export default TcpChattingRoom
