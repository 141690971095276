import Project from "./Project";

// Project Imgs

// Project Imgs styles

const insiderTraderLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/insider-trader.png"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const sudokuSolverLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/sudoku.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const moneyWhereLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/money-where.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const parkspaceLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/parkspace.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const networkingLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/networking.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const loopmaniaLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/loopmania.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const chatterLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/chatter.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const FeetupLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/Feetup.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const Projects = () => {
  return (
    <div className="container">
      <div className="row" style={{ height: "20px" }}></div>
      <div
        className="row p-4"
        style={{
          marginLeft: "10px",
          marginBottom: "-10px",
          fontSize: "36px",
          color: "white",
        }}
      >
        Projects
      </div>
      <div className="row p-5">
        <Project
          anchor="/projects/insider-trader"
          projTitle="Insider Trader Trading Platform"
          contentText="A stock trading platform for buy/selling stock in mock environment."
          custStyle={insiderTraderLogoStyle}
        />
        <Project
          anchor="/projects/sudoku-solver"
          projTitle="Sudoku Solver"
          contentText="A complex Sudoku Solver engine I made myself capable of solving easy, medium and hard difficulty rated Sudoku puzzles."
          custStyle={sudokuSolverLogoStyle}
        />
        <Project
          anchor="/projects/money-where"
          projTitle="Money Where"
          contentText="Real time conversion app that determines, based on which country's conversion rate, your wealth matches a rounded figure."
          custStyle={moneyWhereLogoStyle}
        />
        <Project
          anchor="/projects/parkspace"
          projTitle="Park Space"
          contentText="An app for managing car parking space supply and demand between provider and consumer."
          custStyle={parkspaceLogoStyle}
        />
        <Project
          anchor="/projects/chatter"
          projTitle="Chatter Communications Platform"
          contentText="A backend project accessible through API which enables users to direct message and be part of teams communication."
          custStyle={chatterLogoStyle}
        />
        <Project
          anchor="/projects/tcp-chatting-room"
          projTitle="Protocol TCP Chatting Room"
          contentText="A powerful underlying engine of netcat is socket programming. We leverage this technology in this example to simply talk to friends within a local area network. :)"
          custStyle={networkingLogoStyle}
        />
        <Project
          anchor="/projects/loopmania"
          projTitle="Loop Mania Game"
          contentText="An interactive, multi-leveled battling game where the main player is faced off with fighting different enemies on a 2D map. You can decide to design obstacles, sort/manage player inventory and also form towers to direct your main player towards victory!"
          custStyle={loopmaniaLogoStyle}
        />

        {/* <Project
          replaceAnchor="https://feetup.aayushgore.com"
          projTitle="Feetup"
          contentText="An online marketplace."
          custStyle={FeetupLogoStyle}
        /> */}
        <div
          className="row"
          style={{
            marginLeft: "0px",
            borderRadius: "20px",
            backgroundColor: "white",
            height: "100px",
          }}
        >
          <div className="col d-flex align-items-center justify-content-center">
            <div style={{ fontSize: "24px" }}>More Projects Coming Soon..!</div>
          </div>
        </div>
      </div>

      {/* <Project title="Binary Fuzzer" onClick="/binary_fuzzer"/>
      <Project title="USB Rubber Ducky Virus" onClick="/usb_rubber_ducky_virus"/>
      <Project title="Park Space User Application" onClick="/park_space_user_application"/>
      <Project title="Loop Mania Game Development" onClick="/loop_mania_game_development"/>
      <Project title="Sudoku Solver" onClick="/sudoku_solver"/> */}
    </div>
  );
};

export default Projects;
