import Mother from "./Mother";
import Back from "./Back";

function TodoApp() {
  return (
    <div>
      <Back>
        <Mother />
      </Back>
    </div>
  );
}

export default TodoApp;
