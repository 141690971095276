import { useState } from "react";

// Constants

const backendUrl =
  "https://ork4rciwnpamqsdoxh4b5esezq0gbdsy.lambda-url.ap-southeast-2.on.aws/";

function setup() {
  let tileIds = [];
  let idToInsert = "";
  for (let i = 1; i < 10; i++) {
    for (let j = 1; j < 10; j++) {
      idToInsert = i.toString() + j.toString();

      tileIds.push(idToInsert);
    }
  }
  return tileIds;
}

// Setup tile ids.
const tileIds = setup();

function fetchMatrixTableValues() {
  let matrix = [
    [[], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], []],
    [[], [], [], [], [], [], [], [], []],
  ];
  let searchId = "";
  for (let i = 1; i < 10; i++) {
    for (let j = 1; j < 10; j++) {
      searchId = i.toString() + j.toString();
      if (document.getElementById(searchId).value === "") {
        matrix[i - 1][j - 1].push(0);
      } else {
        matrix[i - 1][j - 1].push(
          parseInt(document.getElementById(searchId).value)
        );
      }
    }
  }
  return matrix;
}

function clear() {
  tileIds.forEach((id) => {
    document.getElementById(id).value = "";
  });
}

function populate(solved_matrix) {
  tileIds.forEach((id) => {
    document.getElementById(id).value = solved_matrix[id[0] - 1][id[1] - 1][0];
  });
}

function greyOutFields() {
  tileIds.forEach((id) => {
    document.getElementById(id).disabled = true;
  });
}

function unGreyOutFields() {
  tileIds.forEach((id) => {
    document.getElementById(id).disabled = false;
  });
}

function SudokuSolver() {
  const [SolveProgress, setSolveProgress] = useState(0);

  function onClickSolve() {
    if (SolveProgress === 2) return;
    // 0. Disable the matrix.
    console.log(
      "Developer: Webpage client has requested to solve Sudoku puzzle."
    );
    // 1. Capture matrix.
    let matrix = fetchMatrixTableValues();
    console.log("Developer: Captured the matrix." + matrix);
    // 2. Clear the fields.
    clear();
    console.log("Developer: Cleared table.");
    greyOutFields();
    // 3. Loading message.
    setSolveProgress(1);
    console.log("Developer: SolveProgress loading.");
    try {
      fetch(backendUrl + "?matrix=" + JSON.stringify(matrix)).then(
        (response) => {
          if (response.status === 429) {
            setSolveProgress(3);
          } else if (response.status === 418) {
            console.log("Hello Aayush 21/04/2024");
            setSolveProgress(4);
          } else if (response.status === 200) {
            console.log("Developer: 200 from backend.");
            populate(response.json());
            setSolveProgress(2);
          }
        }
      );
    } catch (err) {
      console.error("Promise rejected" + err);
    }
  }

  function onClickClear() {
    clear();
    unGreyOutFields();
    setSolveProgress(0);
  }

  return (
    <>
      <div
        className="container w-75"
        style={{
          marginTop: "30px",
          backgroundColor: "white",
          opacity: "0.8",
          height: "800px",
        }}
      >
        <div className="row" style={{ height: "50px" }}></div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <h1>Welcome to Sudoku Solver!</h1>
          </div>
        </div>

        <div className="row" style={{ height: "50px" }}></div>

        <div className="row" style={{ fontSize: "28px" }}>
          <div className="col-1"></div>
          <div className="col d-flex justify-content-center">
            1. Please populate your Sudoku puzzle into the table fields.
          </div>
          <div className="col-1"></div>
        </div>

        <div className="row" style={{ fontSize: "28px" }}>
          <div className="col-1"></div>
          <div className="col d-flex justify-content-center">
            2. Click "Solve".
          </div>
          <div className="col-1"></div>
        </div>

        <div className="row" style={{ fontSize: "28px" }}>
          <div className="col-1"></div>
          <div className="col d-flex justify-content-center">
            3. If you wish to restart, click "Clear".
          </div>
          <div className="col-1"></div>
        </div>

        <div className="row" style={{ height: "20px" }}></div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            {SolveProgress === 1 && (
              <p style={{ color: "orange", fontSize: "26px" }}>Loading... :)</p>
            )}
            {SolveProgress === 2 && (
              <p style={{ color: "green", fontSize: "26px" }}>Solved!</p>
            )}
            {SolveProgress === 3 && (
              <p style={{ color: "red", fontSize: "26px" }}>
                Please try again later. Thank you for your understanding.
              </p>
            )}
            {SolveProgress === 4 && (
              <p style={{ color: "green", fontSize: "26px" }}>
                Puzzle was not solved. Please make sure the puzzle you entered
                is valid.
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            {[2, 3, 4].includes(SolveProgress) && (
              <p style={{ color: "red", fontSize: "26px" }}>
                Click "Clear" to restart.
              </p>
            )}
            {[0, 1].includes(SolveProgress) && (
              <p style={{ height: "80px" }}></p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-4">
            <form action="" style={{ width: "330px" }}>
              {tileIds.map((tileId) => (
                <input
                  key={tileId}
                  type="text"
                  id={tileId}
                  maxLength="1"
                  style={{ height: "35px", width: "35px", textAlign: "center" }}
                ></input>
              ))}
            </form>
          </div>
          <div className="col-1"></div>
          <div className="col-3 d-flex align-items-center">
            <button
              style={{ marginLeft: "-60px" }}
              onClick={onClickSolve}
              disabled={[2, 3, 4].includes(SolveProgress)}
            >
              Solve
            </button>
            <button style={{ marginLeft: "50px" }} onClick={onClickClear}>
              Clear
            </button>
          </div>

          {/* <div className="col-4">
          <form action="" style={{width: '330px'}}>
            {tileIds.map((tileId) => (
              <input key={'s'+tileId} type="text" id={'s'+tileId} maxLength="1" style={{height: '35px', width: '35px', textAlign: 'center'}}></input>
            ))}
          </form>
        </div> */}
        </div>
      </div>
      <footer style={{ height: "50px" }}></footer>
    </>
  );
}

export default SudokuSolver;
