import Project from "./Project";

// Project Imgs

const sudokuSolverLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/sudoku.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const moneyWhereLogoStyle = {
  height: "180px",
  width: "180px",

  borderRadius: "20px",

  // opacity: '0.7',
  backgroundImage: `url(${"/img/money-where.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  // top: '0px',
};

const Tips = () => {
  return (
    <div className="container">
      <div className="row" style={{ height: "20px" }}></div>
      <div
        className="row p-4"
        style={{
          marginLeft: "10px",
          marginBottom: "-10px",
          fontSize: "36px",
          color: "white",
        }}
      >
        Tips
      </div>
      <div className="row p-5">
        <Project
          anchor="/tips/web-dev"
          projTitle="ReactJS Web development"
          contentText="Collated tools, tips and helpful strategies to mitigate disaster or long tedious boring tasks of web dev (learning) journey :)."
          custStyle={sudokuSolverLogoStyle}
        />
        <Project
          anchor="/tips/authentication-authorisation"
          projTitle="Web security strategies"
          contentText="Using tools such as Auth0, OpenID connect, to establish secure online transactions and enhance the security of your developed web app project."
          custStyle={moneyWhereLogoStyle}
        />

        {/* <Project
          replaceAnchor="https://feetup.aayushgore.com"
          projTitle="Feetup"
          contentText="An online marketplace."
          custStyle={FeetupLogoStyle}
        /> */}
        <div
          className="row"
          style={{
            marginLeft: "0px",
            borderRadius: "20px",
            backgroundColor: "white",
            height: "100px",
          }}
        >
          <div className="col d-flex align-items-center justify-content-center">
            <div style={{ fontSize: "24px" }}>More Tips Coming Soon..!</div>
          </div>
        </div>
      </div>

      {/* <Project title="Binary Fuzzer" onClick="/binary_fuzzer"/>
      <Project title="USB Rubber Ducky Virus" onClick="/usb_rubber_ducky_virus"/>
      <Project title="Park Space User Application" onClick="/park_space_user_application"/>
      <Project title="Loop Mania Game Development" onClick="/loop_mania_game_development"/>
      <Project title="Sudoku Solver" onClick="/sudoku_solver"/> */}
    </div>
  );
};

export default Tips;
