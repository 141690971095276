import { Link } from "react-router-dom";

function NavbarItem({ namaeWa, address, isLast }) {
  return (
    <div
      className="col"
      style={isLast ? {} : { borderRight: "2px solid white" }}
    >
      <a style={{ textDecoration: "none", color: "white" }} href={address}>
        {namaeWa}
      </a>
      {/* <Link to="/gaming">{namaeWa}</Link> */}
    </div>
  );
}

export default NavbarItem;
