const Icon = ({ img, placeholder, makeCircle }) => {
  const toApplyStyle = {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "50px",
    backgroundPosition: "center",
    // backgroundRepeat: 'no-repeat',
    backgroundSize: "cover",
    backgroundImage: `url(${img})`,
    cursor: "pointer",
  };

  if (makeCircle) {
    toApplyStyle["borderRadius"] = "50px";
  }

  return (
    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
      <button
        style={toApplyStyle}
        onClick={() => {
          window.open(placeholder, "_blank");
        }}
      ></button>
    </div>
  );
};

export default Icon;
