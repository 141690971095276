const LoopMania = () => {
  window.location.href = 'https://github.com/aayushgore3499/loopmania';
  return (
    <div>
      
    </div>
  )
}

export default LoopMania
