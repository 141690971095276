import NavbarItem from "./NavbarItem";
import { Link } from "react-router-dom";

function Navbar() {
  const NavbarItems = [
    { placeholder: "Home", address: "/" },
    { placeholder: "Projects", address: "/projects" },
    // { placeholder: "Tips", address: "/tips" },
  ];

  if (false)
    return (
      <nav>
        <ul>
          <li>
            <Link to="/gaming">hello</Link>
          </li>
        </ul>
      </nav>
    );

  return (
    <div
      className="d-flex align-items-start justify-content-end"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div
        className="row p-2"
        style={{ marginTop: "10px", marginRight: "5px" }}
      >
        {NavbarItems.map((s) =>
          s.placeholder === NavbarItems[NavbarItems.length - 1].placeholder ? (
            <NavbarItem
              key={s.address}
              namaeWa={s.placeholder}
              address={s.address}
              isLast={true}
            />
          ) : (
            <NavbarItem
              key={s.address}
              namaeWa={s.placeholder}
              address={s.address}
              isLast={false}
            />
          )
        )}
      </div>
    </div>
  );
}

export default Navbar;
