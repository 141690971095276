import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ContactCard from "./ContactCard";
import Projects from "./Projects";

import SudokuSolverV2 from "./SudokuSolverV2";
import MoneyWhere from "./MoneyWhere";
import Parkspace from "./Parkspace";
import TcpChattingRoom from "./TcpChattingRoom";
import LoopMania from "./LoopMania";
import Chatter from "./Chatter";
import Navbar from "./Navbar";
import PageNotFound from "./PageNotFound";
import TodoApp from "../todo/TodoApp";
import Tips from "./Tips";
import AuthorisationAuthentication from "./AuthorisationAuthentication";
import InsiderTrader from "./InsiderTrader";

const backgroundImg = {
  height: "100%",
  width: "100%",
  position: "fixed",
  zIndex: "-1",

  // opacity: "0.7  ",
  backgroundImage: `url(${"/img/background-img.jpg"})`,
  backgroundSize: "cover",
  // backgroundPosition: 'center',
  top: "0px",
};

const AayushApp = () => {
  return (
    <>
      <div style={{ height: "100vh", width: "100%" }}>
        <div style={{ zIndex: "1" }}>
          <Navbar />
          <Router>
            <Routes>
              <Route index element={<ContactCard />} />
              <Route path="projects" element={<Projects />} />

              <Route
                path="projects/insider-trader"
                element={<InsiderTrader />}
              />
              <Route
                path="projects/sudoku-solver"
                element={<SudokuSolverV2 />}
              />
              {/* <Route path="projects/todoapp" element={<TodoApp />} /> */}
              <Route path="projects/money-where" element={<MoneyWhere />} />
              <Route path="projects/parkspace" element={<Parkspace />} />
              <Route
                path="projects/tcp-chatting-room"
                element={<TcpChattingRoom />}
              />
              <Route path="projects/loopmania" element={<LoopMania />} />
              <Route path="projects/chatter" element={<Chatter />} />

              <Route path="tips" element={<Tips />} />
              <Route
                path="/tips/authentication-authorisation"
                element={<AuthorisationAuthentication />}
              />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Router>
        </div>
        <div style={backgroundImg}></div>
      </div>
      {/* <HomeDescription /> */}
    </>
  );
};

export default AayushApp;
